import type { InputMask } from 'imask';
import { fetchIMask } from '../dynamic-modules';

const map = new WeakMap<Element, InputMask<any>>();
function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initPhoneMask(input: HTMLInputElement, dialCode = '971') {
    const IMask = await fetchIMask();

    const getMaskTemplate = (dialCode: string) => {
        switch (dialCode) {
            case '374':
                // Армения
                return `+{${dialCode}} 00 00 00 00`;
            case '375':
                // Беларусь
                return `+{${dialCode}} 00 000-00-00`;
            case '993':
                // Туркменистан
                return `+{${dialCode}} 00 00 00 00`;
            case '994':
                // Азербайджан
                return `+{${dialCode}} 00 000 00 00`;
            case '996':
                // Кыргызстан
                return `+{${dialCode}} 00 000 00 00`;
            case '998':
                // Узбекистан
                return `+{${dialCode}} 00 000 00 00`;
            case '971':
                // ОАЭ
                return `+{${dialCode}} 00 000 0000`;
            default:
                // РФ, Казахстан
                return `+{${dialCode}} (000) 000-00-00`;
        }
    };
    const imask = IMask(input, {
        mask: getMaskTemplate(dialCode),
        prepare: (value: string, maskedPattern: any) => {
            return value;
        },
    });

    map.set(input, imask);
}

export function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

function init(container: Element | Document = document) {
    initPhoneMasks(container);
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    elements.forEach((el) => {
        destroyPhoneMask(el);
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
