import barba from '@barba/core';

barba.hooks.beforeEnter(() => {
    // (window as any).ga?.('set', 'page', window.location.pathname);
    // (window as any).ga?.('send', 'pageview');
    window._tmr?.push({
        id: '3421145',
        type: 'pageView',
        start: new Date().getTime(),
    });
});
