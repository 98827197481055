import gsap from 'gsap';

const map = new Map<HTMLElement, gsap.core.Tween>();

function init(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('.js-parallax-image'));
    parallaxElements.forEach((el) => {
        const item = el.querySelector('.js-parallax-image-item');

        if (item) {
            const tween = gsap.fromTo(
                item,
                {
                    yPercent: -15,
                },
                {
                    yPercent: 15,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: item,
                        scrub: true,
                        start: 'top bottom',
                        end: 'bottom top',
                    },
                },
            );

            map.set(el, tween);
        }
    });
}

function destroy(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('.js-parallax'));
    parallaxElements.forEach((el) => {
        const tween = map.get(el);

        if (tween) {
            tween.scrollTrigger?.kill();
            tween.kill();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
