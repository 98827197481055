let bannerCloseTimeout: NodeJS.Timeout;

const dynamicBannersContainer = document.querySelector('.js-dynamic-banners');

let closePreviousBanner: (() => void) | null = null;

export const openDynamicBanner = (content: string, isTemporary = true) => {
    if (bannerCloseTimeout) {
        clearTimeout(bannerCloseTimeout);
    }

    if (closePreviousBanner) {
        closePreviousBanner();
    }

    const newBanner = document.createElement('div');
    newBanner.classList.add('banner');

    newBanner.innerHTML = `
      <button class="banner__close js-dynamic-banner-close"
        aria-label="Close"
        title="Close"
      >
        Ok
      </button>
      <div class="banner__text js-dynamic-banner-content">${content}</div>
    `;

    if (dynamicBannersContainer) {
        dynamicBannersContainer.appendChild(newBanner);
    }

    const bannerCloseButton = newBanner.querySelector('.js-dynamic-banner-close');

    const handleBannerClose = () => {
        newBanner.classList.remove('banner--visible');

        if (bannerCloseButton) {
            bannerCloseButton.removeEventListener('click', handleBannerClose);
        }

        setTimeout(() => {
            newBanner.remove();
        }, 500);
    };

    if (bannerCloseButton) {
        bannerCloseButton.addEventListener('click', handleBannerClose);

        closePreviousBanner = handleBannerClose;
    }

    if (isTemporary) {
        bannerCloseTimeout = setTimeout(() => {
            handleBannerClose();
        }, 3000);
    }

    requestAnimationFrame(() => {
        setTimeout(() => {
            newBanner.classList.add('banner--visible');
        });
    });
};
