import { fetchIMask } from '@/dynamic-modules';

function init() {
    const phoneMaskInputs = document.querySelectorAll('.js-tel-mask');

    if (phoneMaskInputs) {
        async function initPhoneMask() {
            const IMask = await fetchIMask();

            phoneMaskInputs.forEach((input) => {
                IMask(input, {
                    mask: '+{7}(000)000-00-00',
                    lazy: false,
                });
            });
        }

        initPhoneMask()
    }
}

const _module = { init };

export default _module;
