import gsap from 'gsap';
import { ease1 } from '../../../animations/easings';
import ajaxForms from '@/inits/ajax-forms';
import axios from 'axios';
import phoneModalTimer from '@/utils/phone-code';
import createValidator from '@/modules/validator';
import { openDynamicBanner } from '@/components/dynamic-banner';

const init = (container: Element | Document = document) => {
    const header = container.querySelector<HTMLElement>('.js-header');
    const numberBackBtn = container.querySelector('.js-hp-modal-back-arrow');
    const numberAuthForm = container.querySelector('.js-number-auth') as HTMLFormElement;
    const numberAuthBtn = numberAuthForm?.querySelector('.js-auth-phone-btn');
    const numberRegistrationForm = container.querySelector('.js-number-registration') as HTMLFormElement;
    const numberRegistrationFormBtn = numberRegistrationForm?.querySelector('.js-registration-first-phone-send');
    const confirmForm = container.querySelector('.js-hp-number-confirm') as HTMLFormElement;
    const phoneNumberInner = confirmForm?.querySelector('.js-call-phone-number');
    const registrationForm = container.querySelector<HTMLElement>('.js-system-auth');
    const registerByNumberBtn = container.querySelector('.js-number-registration-btn');

    numberBackBtn?.addEventListener('click', () => {
        gsap.fromTo(
            confirmForm,
            {
                autoAlpha: 1,
                xPercent: 0,
            },
            {
                xPercent: -20,
                autoAlpha: 0,
                ease: ease1,
                duration: 0.5,
                onStart: () => {
                    confirmForm?.classList.remove('active');
                },
                onComplete: () => {
                    confirmForm?.classList.add('disabled');
                },
            },
        );
        gsap.fromTo(
            registrationForm,
            {
                autoAlpha: 0,
                xPercent: 80,
            },
            {
                xPercent: 0,
                autoAlpha: 1,
                ease: ease1,
                duration: 0.5,
                onStart: () => {
                    registrationForm?.classList.remove('disabled');
                },
                onComplete: () => {
                    registrationForm?.classList.add('active');
                },
            },
        );
    });

    let phoneNumberInput: HTMLInputElement | null;
    let hideTimeout: NodeJS.Timeout;
    let reconfirmNumberAction: string;
    let checkAction: string;

    if (registrationForm) {
        numberRegistrationFormBtn?.addEventListener('click', () => {
            confirmNumber('register', numberRegistrationForm, registrationForm);
        });
    }

    if (registrationForm) {
        numberAuthBtn?.addEventListener('click', () => confirmNumber('startAuth', numberAuthForm, registrationForm));
    }

    numberBackBtn?.addEventListener('click', phoneModalTimer.timerStop);

    function confirmNumber(action: string, form: HTMLFormElement, transitionForm: HTMLElement) {
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });
        phoneNumberInput = form?.querySelector<HTMLInputElement>('.js-input-form');
        const phoneNumber = phoneNumberInput?.value;
        if (phoneNumber) {
            const requestBody = {
                params: {
                    params: {
                        phone: phoneNumber,
                        action: action,
                    },
                },
            };

            const isFormValid = validator.validate();
            if (isFormValid) {
                axios
                    .get('/local/ajax/mobileId.php', requestBody)
                    .then((response) => {
                        if (response.data.success && response.data.data.action) {
                            numberRegsitration(phoneNumber, response.data.data.action, response.data.data.method);
                            gsap.fromTo(
                                transitionForm,
                                {
                                    autoAlpha: 1,
                                    xPercent: 0,
                                },
                                {
                                    xPercent: -80,
                                    autoAlpha: 0,
                                    ease: ease1,
                                    duration: 0.5,
                                    onStart: () => {
                                        transitionForm?.classList.remove('active');
                                    },
                                    onComplete: () => {
                                        transitionForm?.classList.add('disabled');
                                    },
                                },
                            );
                            gsap.fromTo(
                                confirmForm,
                                {
                                    autoAlpha: 0,
                                    xPercent: 80,
                                },
                                {
                                    xPercent: 0,
                                    autoAlpha: 1,
                                    ease: ease1,
                                    duration: 0.5,
                                    onStart: () => {
                                        confirmForm?.classList.remove('disabled');
                                    },
                                    onComplete: () => {
                                        confirmForm?.classList.add('active');
                                    },
                                },
                            );
                            // phoneModalTimer.timerStart(confirmNumber, confirmForm);
                            const displayText = `We sent an SMS to the number ${phoneNumber}.
                            Follow the link to confirm the action.`;
                            if (phoneNumberInner) {
                                phoneNumberInner.innerHTML = displayText;
                            }
                        } else {
                            clearTimeout(hideTimeout);
                            if (action === 'register') {
                                ajaxForms.showFormMessages(numberRegistrationForm);
                                hideTimeout = setTimeout(
                                    () => ajaxForms.hideFormMessages(numberRegistrationForm),
                                    5000,
                                );
                                ajaxForms.showFailureMessage(numberRegistrationForm, response.data.message);
                            } else if (action === 'startAuth') {
                                ajaxForms.showFormMessages(form);
                                hideTimeout = setTimeout(() => ajaxForms.hideFormMessages(form), 5000);
                                ajaxForms.showFailureMessage(form, response.data.message);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        openDynamicBanner(error.message);
                    });
            }
        }
    }

    function numberRegsitration(phone: string, action: string, method: string) {
        const requestBody = {
            params: {
                params: {
                    phone: phone,
                    action: action,
                    method: method,
                },
            },
        };

        axios
            .get('/local/ajax/mobileId.php', requestBody)
            .then((response) => {
                clearTimeout(hideTimeout);
                ajaxForms.showFormMessages(confirmForm);

                if (response.data.success) {
                    ajaxForms.showSuccessMessage(confirmForm, response.data.message);
                    window.location.href = '/personal/';
                } else {
                    ajaxForms.showFailureMessage(confirmForm, response.data.message);
                    setTimeout(() => {
                        numberRegsitration(phone, action, method);
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                ajaxForms.showFailureMessage(confirmForm, 'An error occurred while submitting the form');
            });
    }

    if (header) {
        ajaxForms.init(header);
    }
};

const _module = { init };

export default _module;
