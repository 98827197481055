import gsap from 'gsap';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { IdleValue } from 'idlize/IdleValue.mjs';
import { lazyload } from '@/inits/lazy-load';
import ajaxForms from '@/inits/ajax-forms';
import floatedLabels from '@/inits/floated-labels';
import inputMasks from '@/inits/masks';
import revealAnimations from '@/inits/reveal';
import headerTheme from '@/inits/components/header/header-theme';
// import baseSliders from '@/inits/base-sliders';
import actionsSliders from '@/inits/actions-sliders';
import logosSliders from '@/inits/logos-sliders';
import cursor from '../components/cursor';
import { DEVICE_WIDTH } from '../utils/viewport-width';
import { clearScrollTriggers } from '../utils/clear-scrolltriggers';
import parallax from '@/inits/parallax';
import parallaxImages from '@/inits/parallax-images';
import videosOnPage from '@/utils/videosOnPage';
import footerParallaxAnimation from '@/animations/components/footer-parallax';
import intlPhoneInputs from '@/inits/intl-phone-input';
import payment from '@/inits/payment';
import personalAuth from '../inits/pages/personal/personal-auth';
import authTelMask from '../inits/auth-tel-mask';
import productModel from '../inits/product-model';
import productModelPopup from '../inits/product-model-popup';

export function initBaseView() {
    const beforeEnterQueue = new IdleQueue();
    // let baseEnterTl: gsap.core.Timeline | null;
    let autoPlayedVideos: HTMLVideoElement[] = [];

    const autoplayedVideosPlayStateObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const target = entry.target as HTMLElement;

                if (target instanceof HTMLVideoElement) {
                    if (entry.isIntersecting) {
                        target.play();
                        target.style.visibility = '';
                    } else {
                        target.pause();
                        target.style.visibility = 'hidden';
                    }
                }
            });
        },
        { rootMargin: '100px' },
    );

    barba.hooks.beforeEnter((data) => {
        if (data) {
            // Адаптивные таблицы в wysiwyg
            // Array.from(data.next.container.querySelectorAll('.wysiwyg table')).forEach((el) => {
            //     const wrapper = document.createElement('div');
            //     wrapper.className = 'table-responsive';
            //     wrap(el, wrapper);
            // });

            parallax.init(data.next.container);
            parallaxImages.init(data.next.container);
        }

        // baseEnterTl = new IdleValue(() => createInitialInnerPageTimeline(data?.next.container));

        beforeEnterQueue.pushTask(() => {
            if (data) {
                autoPlayedVideos = Array.from(
                    data.next.container.querySelectorAll<HTMLVideoElement>('video[autoplay]'),
                );

                autoPlayedVideos.forEach((video) => {
                    video.play();
                    autoplayedVideosPlayStateObserver.observe(video);
                });
            }
        });

        beforeEnterQueue.pushTask(() => {
            actionsSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            logosSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            personalAuth.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            authTelMask.init();
        });

        beforeEnterQueue.pushTask(() => {
            floatedLabels.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            inputMasks.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            intlPhoneInputs.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            ajaxForms.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            payment.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            const pagePopups = data?.next.container.querySelector('.js-popups');
            const pagePopupsContainer = document.querySelector('.js-popups-container');

            if (pagePopups && pagePopupsContainer) {
                pagePopupsContainer.appendChild(pagePopups);
            }
        });
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        if (DEVICE_WIDTH.isDesktop) {
            cursor.initForPage(data?.next.container);
        }

        videosOnPage.init(data?.next.container);
        footerParallaxAnimation.updateForPage();

        beforeEnterQueue.pushTask(() => {
            headerTheme.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            if (data) {
                revealAnimations.init(
                    data.next.namespace === 'text-page' ? data.next.container.parentElement! : data.next.container,
                );
            }
        });

        beforeEnterQueue.pushTask(() => {
            productModel.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            productModelPopup.init(data?.next.container);
        });

        if (DEVICE_WIDTH.isDesktop) {
            gsap.set('.c-scrollbar', { opacity: 1, delay: 0.3 });
        }

        //metrica
        if (data?.next.namespace === 'product-page') {
            const infoEl = data.next.container.querySelector<HTMLElement>('.js-hero-info');
            const title = data.next.container.querySelector('.js-pr-hero__title');
            const productName = title?.innerHTML;
            const productId = infoEl?.dataset.productId;
            const productPrice = infoEl?.dataset.price ? parseFloat(infoEl.dataset.price) : null;

            if (productPrice && productId) {
                window.dataLayer?.push({
                    ecommerce: {
                        currencyCode: 'AED',
                        detail: {
                            products: [
                                {
                                    id: productName,
                                    price: productPrice,
                                },
                            ],
                        },
                    },
                });

                window._tmr?.push({
                    type: 'reachGoal',
                    id: '3421145',
                    value: productPrice,
                    goal: 'Catalog',
                    params: { product_id: parseInt(productId) },
                });
            }
        }

    });

    barba.hooks.beforeLeave((data) => {
        beforeEnterQueue.clearPendingTasks();
        ajaxForms.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
        revealAnimations.destroy(data?.current.container);
        floatedLabels.destroy(data?.current.container);
        payment.destroy();
        cursor.destroyForPage();

        const pagePopupsContainer = document.querySelector('.js-popups-container');

        if (pagePopupsContainer) {
            pagePopupsContainer.innerHTML = '';
        }

        autoPlayedVideos.forEach((el) => {
            autoplayedVideosPlayStateObserver.unobserve(el);
        });
        autoPlayedVideos = [];

        if (DEVICE_WIDTH.isDesktop) {
            gsap.set('.c-scrollbar', { opacity: 0 });
        }
    });

    barba.hooks.afterLeave((data) => {
        clearScrollTriggers();
        headerTheme.destroy(data?.current.container);
        actionsSliders.destroy(data?.current.container);
        logosSliders.destroy(data?.current.container);
        parallax.destroy(data?.current.container);
        parallaxImages.destroy(data?.current.container);
        intlPhoneInputs.destroy(data?.current.container);
        actionsSliders.destroy(data?.current.container);
        productModel.destroy();
        productModelPopup.destroy();
        document.body.classList.remove('no-scroll');
    });
}
