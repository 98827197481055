import delegate from 'delegate';
import createFormSender from '@/modules/ajax-form-sender';
import createValidator from '@/modules/validator';
import { clearConfigCache } from 'prettier';
import axios from 'axios';
import { openDynamicBanner } from '@/components/dynamic-banner';
// import { removeButtonLoading, setButtonLoading } from '@/utils/loading-btn';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<
    HTMLFormElement,
    {
        sender: ReturnType<typeof createFormSender>;
        submitFn: EventListener;
        focusFn: EventListener;
        closeDelegation: any;
    }
>();

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = false;
    }

    if (failureBlock) {
        failureBlock.hidden = true;
    }

    if (successMessage) {
        successMessage.innerHTML = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = true;
    }

    if (failureBlock) {
        failureBlock.hidden = false;
    }

    if (failureMessage) {
        failureMessage.innerHTML = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    messagesContainer?.classList.remove('form-messages--hidden');
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    messagesContainer?.classList.add('form-messages--hidden');

    setTimeout(() => {
        if (successBlock) {
            successBlock.hidden = true;
        }

        if (failureBlock) {
            failureBlock.hidden = true;
        }
    }, 300);
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));
    forms.forEach((form) => {
        let isSubmitting = false;
        let hideTimeout: NodeJS.Timeout;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const shouldClearInputs = !form.classList.contains('do-not-clear');

        const sender = createFormSender(form, {
            shouldClearInputs,
            headers: {
                ['X-Requested-With']: 'XMLHttpRequest',
            },
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ success, message, data, error }) => {
                if (data && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
                if (data && data.refreshPage) {
                    window.location.reload();
                }
                if (data && data.redirect) {
                    window.location.href = data.redirect;
                }
                if (success) {
                    showSuccessMessage(form, message);
                } else {
                    showFailureMessage(form, message);
                }
                if (error) {
                    showFailureMessage(form, error);
                }
            },
            onError: () => {
                showFailureMessage(form, 'An error occurred while submitting the form');
            },
            onComplete: () => {
                clearTimeout(hideTimeout);
                showFormMessages(form);
                hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();
            // const submitBtn = form.querySelector<HTMLButtonElement>('button[type="submit"]');

            if (isFormValid) {
                isSubmitting = true;

                // if (submitBtn) {
                //     setButtonLoading(submitBtn);
                // }

                sender.send().finally(() => {
                    isSubmitting = false;

                    // if (submitBtn) {
                    //     removeButtonLoading(submitBtn);
                    // }
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            const inputGroup = this.closest<HTMLElement>('.input-group');

            if (inputGroup) {
                validator.clearInput(inputGroup);
            }
        }

        // function onBlur(this: HTMLInputElement) {
        //     validator.validateInput(this);
        // }

        validator.inputGroups.forEach((inputGroup) => {
            const input = inputGroup.querySelector<HTMLInputElement>(
                'input[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), select[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"]), textarea[name]:not([type="submit"]):not([type="reset"]):not([type="hidden"])',
            );

            input?.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
        const closeDelegation = delegate(form, '.js-remove-message', 'click', () => {
            clearTimeout(hideTimeout);
            hideFormMessages(form);
        });
        map.set(form, { sender, submitFn, focusFn: onFocus, closeDelegation });
    });
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            data.closeDelegation.destroy();
            form.removeEventListener('submit', data.submitFn);
            Array.from(form.querySelectorAll('input')).forEach((input) => {
                input.removeEventListener('focus', data.focusFn);
            });
        }
    });
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = {
    init,
    destroy,
    getInstanceByElement,
    showSuccessMessage,
    showFormMessages,
    showFailureMessage,
    hideFormMessages,
};

export default _module;
