import { LitPopupElement } from '@/custom-elements/LitPopupElement';
import { addProductToCardWithBuyBtn } from './components/header/header-cart-handlers';

let instance: any;
let productBuyEl: HTMLElement | null;

function onBuyLinkClick(this: HTMLElement) {
    const { id } = this.dataset;

    if (id) {
        const realBuyLinkEl = document.querySelector<HTMLElement>(`.js-product-buy-btn[data-id="${id}"]`);
        realBuyLinkEl?.click();
    }
}

function init(container: HTMLElement | Document = document) {
    const canvas = container.querySelector<HTMLCanvasElement>('canvas.js-product-popup-canvas');
    const productNameEl = container.querySelector<HTMLElement>('.js-product-canvas-popup__product-name');
    const productDescriptionEl = container.querySelector<HTMLElement>('.js-product-canvas-popup__product-description');
    const productPriceEls = Array.from(
        container.querySelectorAll<HTMLElement>('.js-product-canvas-popup__product-price'),
    );
    const productDetailLinkEl = container.querySelector<HTMLAnchorElement>(
        'a.js-product-canvas-popup__product-detail-link',
    );
    productBuyEl = container.querySelector<HTMLElement>('.js-product-canvas-popup__product-buy-link');

    productBuyEl?.addEventListener('click', addProductToCardWithBuyBtn);

    if (canvas) {
        Array.from(container.querySelectorAll<HTMLElement>('[data-lit-popup-open="product-model"]')).forEach((btn) => {
            btn.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                const {
                    productName,
                    productTextName,
                    productDescription,
                    productPrice,
                    productDetailLink,
                    productId,
                    productImg,
                } = btn.dataset;

                if (productTextName && productNameEl) {
                    productNameEl.textContent = productTextName;
                }

                if (productDescription && productDescriptionEl) {
                    productDescriptionEl.textContent = productDescription;
                }

                if (productPrice) {
                    productPriceEls.forEach((el) => {
                        el.textContent = productPrice;
                    });
                }

                if (productDetailLink && productDetailLinkEl) {
                    productDetailLinkEl.href = productDetailLink;
                }

                if (productBuyEl) {
                    if (productId) {
                        productBuyEl.dataset.id = productId;
                    }

                    if (productTextName) {
                        productBuyEl.dataset.name = productTextName;
                    }

                    if (productDescription) {
                        productBuyEl.dataset.description = productDescription;
                    }

                    if (productImg) {
                        productBuyEl.dataset.img = productImg;
                    }
                }

                if (productName) {
                    if (instance) {
                        instance.setProduct(productName);
                        instance.resume();
                    } else {
                        import('../components/product-3d' /* webpackChunkName: "product-3d" */).then(
                            ({ createProductModel }) => {
                                instance = createProductModel(canvas, productName);
                            },
                        );
                    }

                    const popupEl = container.querySelector<LitPopupElement>(
                        'app-lit-popup[data-lit-popup="product-model"]',
                    );

                    if (popupEl) {
                        popupEl.open();
                        popupEl.addEventListener(
                            'close',
                            () => {
                                instance?.pause();
                            },
                            { once: true },
                        );
                    }
                }
            });
        });
    }
}

function destroy() {
    if (productBuyEl) {
        productBuyEl.removeEventListener('click', onBuyLinkClick);
        productBuyEl = null;
    }

    if (instance) {
        instance.destroy();
        instance = null;
    }
}

export default { init, destroy };
